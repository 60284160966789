var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-toolbar",
    { attrs: { dense: "", color: _vm.color, dark: "" } },
    [
      _c("v-toolbar-title", { staticClass: "text-subtitle-1" }, [
        _vm._v(_vm._s(_vm.label))
      ]),
      _vm.is_verified
        ? _c(
            "v-chip",
            {
              staticClass: "text-uppercase ml-1",
              attrs: { small: "", color: _vm.highlightColor }
            },
            [
              _c("v-icon", { staticClass: "mr-1", attrs: { small: "" } }, [
                _vm._v("mdi-check")
              ]),
              _vm._v("Verified")
            ],
            1
          )
        : _c(
            "v-chip",
            {
              staticClass: "text-uppercase ml-1",
              attrs: { small: "", color: "warning darken-2" }
            },
            [
              _c("v-icon", { staticClass: "mr-1", attrs: { small: "" } }, [
                _vm._v("mdi-close")
              ]),
              _vm._v("UnVerified")
            ],
            1
          ),
      _c("v-spacer"),
      _vm._l(_vm.items, function(item, index) {
        return [
          _c(
            "v-tooltip",
            {
              key: index,
              attrs: { top: "", color: _vm.highlightColor },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function(ref) {
                      var on = ref.on
                      var attrs = ref.attrs
                      return [
                        _c(
                          "v-btn",
                          _vm._g(
                            _vm._b(
                              {
                                staticClass: "mr-2",
                                attrs: { color: _vm.btnColor },
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    return _vm.$emit(
                                      "toolbar-action",
                                      item.name
                                    )
                                  }
                                }
                              },
                              "v-btn",
                              attrs,
                              false
                            ),
                            on
                          ),
                          [_c("v-icon", [_vm._v(_vm._s(item.icon))])],
                          1
                        )
                      ]
                    }
                  }
                ],
                null,
                true
              )
            },
            [_c("span", [_vm._v(_vm._s(item.tooltip))])]
          )
        ]
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }