<template>
    <v-toolbar dense :color="color" dark>
        <v-toolbar-title class="text-subtitle-1">{{ label }}</v-toolbar-title>
        <v-chip
            v-if="is_verified"
            small
            :color="highlightColor"
            class="text-uppercase ml-1"
            ><v-icon small class="mr-1">mdi-check</v-icon>Verified</v-chip
        >
        <v-chip
            v-else
            small
            color="warning darken-2"
            class="text-uppercase ml-1"
            ><v-icon small class="mr-1">mdi-close</v-icon>UnVerified</v-chip
        >
        <v-spacer></v-spacer>
        <template v-for="(item, index) in items">
            <v-tooltip top :color="highlightColor" :key="index">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        :color="btnColor"
                        class="mr-2"
                        v-on="on"
                        v-bind="attrs"
                        @click.stop="$emit('toolbar-action', item.name)"
                    >
                        <v-icon>{{ item.icon }}</v-icon>
                    </v-btn>
                </template>
                <span>{{ item.tooltip }}</span>
            </v-tooltip>
        </template>
    </v-toolbar>
</template>
<script>
export default {
    name: "widget-toolbar",
    components: {},
    props: {
        label: {
            type: String,
            required: true,
        },
        is_verified: {
            type: Boolean,
            required: false,
            default: false,
        },
        btnColor: {
            type: String,
            required: false,
            default: "primary darken-1",
        },
        color: {
            type: String,
            required: false,
            default: "primary",
        },
        highlightColor: {
            type: String,
            required: false,
            default: "secondary",
        },
        toolbarItems: {
            type: String,
            required: false,
            default: "screen.rentalAddressToolBarItems",
        },
    },
    data() {
        return {
            items: this.$config(this.toolbarItems),
        };
    },
};
</script>

<style scoped>
</style>